import Ahmad from '../../images/mpk/prelegent/ahmad.jpg';
import KarolinaWilk from '../../images/mpk/prelegent/karolinawilk.jpeg';
import Kosecki from '../../images/mpk/prelegent/Kosecki_pic.jpg';
import frankowski from '../../images/mpk/prelegent/frankowski.jpg';
import kaczorowska from '../../images/mpk/prelegent/kaczorowska.jpg';
import kalinowski from '../../images/mentoring/jurors/jerzy-kalinowski.jpg';
import kazimierczak from '../../images/mpk/prelegent/kazimierczak.jpg';
import majewska from '../../images/mpk/prelegent/majewska.jpg';
import majewski from '../../images/mpk/prelegent/majewski.jpg';
import malolepszy from '../../images/mpk/prelegent/malolepszy.jpg';
import martyniuk from '../../images/mentoring/mentors/martyniuk.jpg';
import mejszutowicz from '../../images/mpk/prelegent/mejszutowicz.jpg';
import nalbert from '../../images/mpk/prelegent/nalbert.jpg';
import raciborski from '../../images/mentoring/jurors/kacper-raciborski.jpg';
import raczynski from '../../images/mpk/prelegent/raczynski.jpg';
import sobczyk from '../../images/mpk/prelegent/sobczyk.jpg';
import stanek from '../../images/mpk/prelegent/stanek.jpg';
import sugier from '../../images/mpk/prelegent/sugier.jpg';
import szyszkowski from '../../images/mpk/prelegent/szyszkowski.jpg';
import younis from '../../images/mpk/prelegent/younis.jpg';

const DayOne = [
  {
    name: 'Rejestracja',
    isBreak: true,
    startTime: '2023-09-25T09:30+02:00',
    panelLength: '25',
    icon: 'register'
  },
  {
    name: 'Otwarcie Dnia',
    isBreak: true,
    startTime: '2023-09-25T09:55+02:00',
    panelLength: '5',
    icon: 'opening'
  },
  {
    name: 'Introduction to the venture world',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Karolina Wilk Tryjanowska',
    speaker: {
      name: 'Karolina Wilk Tryjanowska',
      photo: {
        file: {
          url: KarolinaWilk
        }
      }
    },
    startTime: '2023-09-25T10:00+02:00',
    panelLength: '60'
  },
  {
    name: 'Przerwa Kawowa',
    isBreak: true,
    startTime: '2023-09-25T11:00+02:00',
    panelLength: '15',
    icon: 'coffee'
  },
  {
    name: 'Wprowadzenie do modułowego canvasu Fundacji Innovations Hub',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Kacper Raciborski',
    speaker: {
      name: 'Kacper Raciborski',
      photo: {
        file: {
          url: raciborski
        }
      }
    },
    startTime: '2023-09-25T11:15+02:00',
    panelLength: '45'
  },
  {
    name: 'Przerwa Obiadowa',
    isBreak: true,
    startTime: '2023-09-25T12:00+02:00',
    panelLength: '45',
    icon: 'pizza'
  },
  {
    name: 'Bootstraping',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Jędrzej Małolepszy',
    speaker: {
      name: 'Jędrzej Małolepszy',
      photo: {
        file: {
          url: malolepszy
        }
      }
    },
    startTime: '2023-09-25T12:45+02:00',
    panelLength: '60'
  },
  {
    name: 'Crowdfunding i Crowdinvesting',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Piotr Majewski',
    speaker: {
      name: 'Piotr Majewski',
      photo: {
        file: {
          url: majewski
        }
      }
    },
    startTime: '2023-09-25T13:45+02:00',
    panelLength: '75'
  },
  {
    name: 'Przerwa Kawowa',
    isBreak: true,
    startTime: '2023-09-25T15:00+02:00',
    panelLength: '15',
    icon: 'coffee'
  },
  {
    name: 'Crowdfunding i Crowdinvesting',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Michał Stanek',
    speaker: {
      name: 'Michał Stanek',
      photo: {
        file: {
          url: stanek
        }
      }
    },
    startTime: '2023-09-25T15:15+02:00',
    panelLength: '60'
  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2023-09-25T16:15+02:00',
    panelLength: '30',
    icon: 'networking'
  }
];

const DayTwo = [
  {
    name: 'Rejestracja',
    isBreak: true,
    startTime: '2023-09-26T09:30+02:00',
    panelLength: '25',
    icon: 'register'
  },
  {
    name: 'Otwarcie Dnia',
    isBreak: true,
    startTime: '2023-09-26T09:55+02:00',
    panelLength: '5',
    icon: 'opening'
  },
  {
    name: 'Rozwój innowacyjności i przedsiębiorczości na Mazowszu - Jak skutecznie aplikować i realizować projekty z dofinansowaniem z Unii Europejskiej',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Mariusz Frankowski',
    speaker: {
      name: 'Mariusz Frankowski',
      photo: {
        file: {
          url: frankowski
        }
      }
    },
    startTime: '2023-09-26T10:00+02:00',
    panelLength: '60'
  },
  {
    name: 'Przerwa Kawowa',
    isBreak: true,
    startTime: '2023-09-26T11:00+02:00',
    panelLength: '15',
    icon: 'coffee'
  },
  {
    name: 'Cykl życia biznesu i jego formy finansowania',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Paulina Kimla-Kaczorowska',
    speaker: {
      name: 'Paulina Kimla-Kaczorowska',
      photo: {
        file: {
          url: kaczorowska
        }
      }
    },
    startTime: '2023-09-26T11:15+02:00',
    panelLength: '75'
  },
  {
    name: 'Przerwa Obiadowa',
    isBreak: true,
    startTime: '2023-09-26T12:30+02:00',
    panelLength: '45',
    icon: 'pizza'
  },
  {
    name: 'Finansowanie dłużne i VC, przedsiębiorstw ze środków UE, BGK',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Mikołaj Szyszkowski',
    speaker: {
      name: 'Mikołaj Szyszkowski',
      photo: {
        file: {
          url: szyszkowski
        }
      }
    },
    startTime: '2023-09-26T13:15+02:00',
    panelLength: '45'
  },
  {
    name: 'Programy Krajowe, PARP',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Karolina Kazimierczak',
    speaker: {
      name: 'Karolina Kazimierczak',
      photo: {
        file: {
          url: kazimierczak
        }
      }
    },
    startTime: '2023-09-26T14:00+02:00',
    panelLength: '45'
  },
  {
    name: 'Przerwa Kawowa',
    isBreak: true,
    startTime: '2023-09-26T14:45+02:00',
    panelLength: '15',
    icon: 'coffee'
  },
  {
    name: 'Tokenizacja - innowacyjna metoda pozyskiwania rozproszonego kapitału i budowania społeczności',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Piotr Sobczyk, Marcin Walkowski',
    speaker: {
      name: 'Piotr Sobczyk, Marcin Walkowski',
      photo: {
        file: {
          url: sobczyk
        }
      }
    },
    startTime: '2023-09-26T15:00+02:00',
    panelLength: '90'
  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2023-09-26T16:30+02:00',
    panelLength: '30',
    icon: 'networking'
  }
];

const DayThree = [
  {
    name: 'Rejestracja',
    isBreak: true,
    startTime: '2023-09-27T09:30+02:00',
    panelLength: '25',
    icon: 'register'
  },
  {
    name: 'Otwarcie Dnia',
    isBreak: true,
    startTime: '2023-09-27T09:55+02:00',
    panelLength: '5',
    icon: 'opening'
  },
  {
    name: 'Wprowadzenie do Inkubatorów',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Jan Raczyński',
    speaker: {
      name: 'Jan Raczyński',
      photo: {
        file: {
          url: raczynski
        }
      }
    },
    startTime: '2023-09-27T10:00+02:00',
    panelLength: '75'
  },
  {
    name: 'Akces NCBR',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Konrad Kosecki',
    speaker: {
      name: 'Konrad Kosecki',
      photo: {
        file: {
          url: Kosecki
        }
      }
    },
    startTime: '2023-09-27T11:15+02:00',
    panelLength: '30'
  },
  {
    name: 'Przerwa Kawowa',
    isBreak: true,
    startTime: '2023-09-27T11:45+02:00',
    panelLength: '15',
    icon: 'coffee'
  },
  {
    name: 'Wprowadzenie do Akceleratorów',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Maria Tyka-Majewska',
    speaker: {
      name: 'Maria Tyka-Majewska',
      photo: {
        file: {
          url: majewska
        }
      }
    },
    startTime: '2023-09-27T12:00+02:00',
    panelLength: '60'
  },
  {
    name: 'Odkrywanie sukcesu w erze AI: Opanowanie porażek dla szybkich osiągnięć',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Ahmad Piraiee',
    speaker: {
      name: 'Ahmad Piraiee',
      photo: {
        file: {
          url: Ahmad
        }
      }
    },
    startTime: '2023-09-27T13:00+02:00',
    panelLength: '60'
  },
  {
    name: 'Przerwa Obiadowa',
    isBreak: true,
    startTime: '2023-09-27T14:00+02:00',
    panelLength: '45',
    icon: 'pizza'
  },
  {
    name: 'Wprowadzenie do Akceleratorów',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Tarek Younis',
    speaker: {
      name: 'Tarek Younis',
      photo: {
        file: {
          url: younis
        }
      }
    },
    startTime: '2023-09-27T14:45+02:00',
    panelLength: '60'
  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2023-09-27T15:45+02:00',
    panelLength: '45',
    icon: 'networking'
  }
];

const DayFour = [
  {
    name: 'Rejestracja',
    isBreak: true,
    startTime: '2023-09-28T09:30+02:00',
    panelLength: '25',
    icon: 'register'
  },
  {
    name: 'Otwarcie Dnia',
    isBreak: true,
    startTime: '2023-09-28T09:55+02:00',
    panelLength: '5',
    icon: 'opening'
  },
  {
    name: 'Od Inkubatorów do VC, sztuka modelowania startupów',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Kacper Raciborski',
    speaker: {
      name: 'Kacper Raciborski',
      photo: {
        file: {
          url: raciborski
        }
      }
    },
    startTime: '2023-09-28T10:00+02:00',
    panelLength: '45'
  },
  {
    name: 'Przerwa Kawowa',
    isBreak: true,
    startTime: '2023-09-28T10:45+02:00',
    panelLength: '15',
    icon: 'coffee'
  },
  {
    name: 'Pozyskiwanie finansowania od funduszy Venture Capital',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Dawid Sugier',
    speaker: {
      name: 'Dawid Sugier',
      photo: {
        file: {
          url: sugier
        }
      }
    },
    startTime: '2023-09-28T11:00+02:00',
    panelLength: '60'
  },
  {
    name: 'Pozyskiwanie finansowania od funduszy Venture Capital',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Ireneusz Martyniuk',
    speaker: {
      name: 'Ireneusz Martyniuk',
      photo: {
        file: {
          url: martyniuk
        }
      }
    },
    startTime: '2023-09-28T12:00+02:00',
    panelLength: '60'
  },
  {
    name: 'Przerwa Obiadowa',
    isBreak: true,
    startTime: '2023-09-28T13:00+02:00',
    panelLength: '45',
    icon: 'pizza'
  },
  {
    name: 'Pozyskiwanie inwestora strategicznego, branżowego lub Anioła Biznesu',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Jerzy Kalinowski',
    speaker: {
      name: 'Jerzy Kalinowski',
      photo: {
        file: {
          url: kalinowski
        }
      }
    },
    startTime: '2023-09-28T13:45+02:00',
    panelLength: '105'
  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2023-09-28T15:30+02:00',
    panelLength: '30',
    icon: 'networking'
  }
];

const DayFive = [
  {
    name: 'Rejestracja',
    isBreak: true,
    startTime: '2023-09-29T10:00+02:00',
    panelLength: '25',
    icon: 'register'
  },
  {
    name: 'Otwarcie Dnia',
    isBreak: true,
    startTime: '2023-09-29T10:30+02:00',
    panelLength: '5',
    icon: 'opening'
  },
  {
    name: 'Private Equity, Leveraged Buyout, Management Buyout',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Jan Nalbert',
    speaker: {
      name: 'Jan Nalbert',
      photo: {
        file: {
          url: nalbert
        }
      }
    },
    startTime: '2023-09-29T11:00+02:00',
    panelLength: '60'
  },
  {
    name: 'Przerwa Obiadowa',
    isBreak: true,
    startTime: '2023-09-29T12:15+02:00',
    panelLength: '45',
    icon: 'pizza'
  },
  {
    name: 'Private Equity, Leveraged Buyout, Management Buyout z perspektywy prawnej',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Paulina Kimla-Kaczorowska',
    speaker: {
      name: 'Paulina Kimla-Kaczorowska',
      photo: {
        file: {
          url: kaczorowska
        }
      }
    },
    startTime: '2023-09-29T13:00+02:00',
    panelLength: '60'
  },
  {
    name: 'NewConnect – gdzie innowacja spotyka kapitał',
    isBreak: false,
    activityType: 'Wykład',
    speakers: 'Dariusz Mejszutowicz',
    speaker: {
      name: 'Dariusz Mejszutowicz',
      photo: {
        file: {
          url: mejszutowicz
        }
      }
    },
    startTime: '2023-09-29T14:00+02:00',
    panelLength: '45'
  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2023-09-29T14:45+02:00',
    panelLength: '45',
    icon: 'networking'
  }
];


export const MPKAgenda = [DayOne, DayTwo, DayThree, DayFour, DayFive];
