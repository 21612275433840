import {
  AgendaBar,
  MobileAgendaBar
} from '../../templates/workshops/components/agenda';
import {
  AgendaNav,
  AgendaSubHeader,
  AgendaTitleWrapper,
  ContentWrapper,
  ImageGrid,
  MPKFundingInfo,
  MPKSubHeader,
  MainHeader,
  MainImg,
  MpkInverviewFlex,
  SponsorImage
} from './styled.components';
import { Flex, PageWrapper } from '../news/styled.components';
import { PageSection, SectionWrapper } from '../../components/global/flexbox';
import React, { useState } from 'react';
import { SectionHeader, SubHeader, Text } from '../../components/typography';
import {
  StyledButton,
  StyledHref
} from '../../components/global/buttons/styled.components';
import { Trans, useTranslation } from 'react-i18next';

import AstraZeneca from '../../images/logos/as.png';
import Bubbles1 from '../../images/home/theme-circles/circles-elements-our-mission.png';
import Bubbles2 from '../../images/home/theme-circles/circle-elements-mid.png';
import Bubbles3 from '../../images/home/theme-circles/circle-elements-left.png';
import Bubbles4 from '../../images/home/theme-circles/circle-elements-academy-initiative.png';
import GPW from '../../images/logos/gpw.png';
import { GalleryComponent } from '../../components/galeria/index.old';
import { GalleryImageData } from '../../mocks/gallery';
import Hero from '../../images/mpk/hero.png';
import Layout from '../../components/layout/layout';
import MPK from '../../images/logos/logo-mpk.png';
import { MPKAgenda } from './agenda';
import { MPKAgendaEN } from './agendaEN';
import MPKSpeakers from '../../mocks/mpk-testimonial';
import NCBR from '../../images/logos/ncbr.png';
import NIW from '../../images/logos/niw.png';
import NTFY from '../../images/logos/ntfy.png';
import OFF from '../../images/logos/off.png';
import PARP from '../../images/logos/parp.png';
import RP from '../../images/logos/flaga-godlo.png';
import SGH from '../../images/logos/skn.png';
import Seo from '../../components/seo';
import Testimonials from '../../components/testimonials';
import { ThemeProvider } from 'styled-components';
import Vestbee from '../../images/logos/vestbee.png';
import { isWindow } from '../../components/global/utils';
import styled from 'styled-components';
import { theme } from '../../themes/default';

function MpkPage() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const [currentPage, setCurrentPage] = useState(1);
  const MPKCurrentAgenda = currentLang === 'pl' ? MPKAgenda : MPKAgendaEN;
  const ITEMS_PER_PAGE = 1;
  const totalPages = Math.ceil(MPKCurrentAgenda.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentAgendaItems = MPKCurrentAgenda.slice(startIndex, endIndex);

  function setDay(index) {
    setCurrentPage(index + 1);
  }

  function nextDay() {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }

  function prevDay() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  const isMobile = isWindow() && window.innerWidth < 768;

  return (
    <ThemeProvider theme={theme}>
      <Layout withHeader={false}>
        <Seo title={t`mpk-seo-title`} description={t`mpk-seo-dsc`} />
        <PageBox id="mpk-page">
          <PageWrapper>
            <PageSection
              flex_align="center"
              bg_img={Hero}
              bg_img_size="cover"
              bg_img_pos="top center"
              className="hero">
              <SectionWrapper style={{ alignItems: 'center', display: 'flex' }}>
                <Flex
                  style={{ height: 'auto' }}
                  justify="center"
                  direction="column"
                  container_gap="10px">
                  <MainImg src={MPK} alt="mpk logo" />
                  <MainHeader>{t('mpk-header')}</MainHeader>
                  <StyledHref
                    style={{ marginBottom: '40px' }}
                    href="#interviews"
                    className="outlined"
                    to="/">
                    {t('mpk-find-out')}
                  </StyledHref>
                </Flex>
              </SectionWrapper>
            </PageSection>
            <PageSection
              bg_img={Bubbles1}
              bg_img_size="200px"
              bg_img_pos="center right"
              bg_img_size_mobile="20%">
              <SectionWrapper>
                <MpkInverviewFlex container_gap="40px">
                  <ContentWrapper>
                    <SectionHeader>
                      <strong>{t('about-mpk')}</strong>
                    </SectionHeader>
                    <Text>{t('about-mpk-content')}</Text>
                  </ContentWrapper>
                  <iframe
                    className="mpk-video"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/iifDWvU-7Jk?si=hqSJkxa7z4nKpTDv&amp;controls=0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
                </MpkInverviewFlex>
              </SectionWrapper>
            </PageSection>
            <PageSection bg_img={Bubbles3} bg_img_pos="bottom left">
              <SectionWrapper>
                <div className="flex-child">
                  <SectionHeader>
                    <strong>{t('mpk-agenda')}</strong>
                  </SectionHeader>
                  <Text className="center">{t('about-mpk-agenda-text')}</Text>
                </div>
                <div className="flex-child" style={{ paddingTop: '60px' }}>
                  <AgendaTitleWrapper justify="space-around">
                    {!isMobile ? (
                      MPKAgenda.map((element, index) => {
                        return (
                          <AgendaSubHeader
                            className={
                              currentPage === index + 1
                                ? 'active small'
                                : 'small'
                            }
                            onClick={() => setDay(index)}>
                            {t('mpk-day') + (index + 1)}
                          </AgendaSubHeader>
                        );
                      })
                    ) : (
                      <>
                        <AgendaNav onClick={() => prevDay()}>←</AgendaNav>
                        <AgendaSubHeader>
                          {t('mpk-day') + currentPage}
                        </AgendaSubHeader>
                        <AgendaNav onClick={() => nextDay()}>→</AgendaNav>
                      </>
                    )}
                  </AgendaTitleWrapper>
                  {currentAgendaItems.map((element, index) => (
                    <div key={index}>
                      {element.map((item) => {
                        if (isMobile) {
                          return (
                            <MobileAgendaBar key={item.id} {...item} isEvent />
                          );
                        }
                        return <AgendaBar key={item.id} {...item} isEvent />;
                      })}
                    </div>
                  ))}
                </div>
              </SectionWrapper>
            </PageSection>
            <PageSection bg_img={Bubbles4} bg_img_pos="center right">
              <SectionWrapper>
                <SectionHeader>
                  <strong>{t('mpk-jury')}</strong>
                </SectionHeader>
                <Text className="center">{t('mpk-jury-content')}</Text>
                <Testimonials content={MPKSpeakers} />
              </SectionWrapper>
            </PageSection>
            <PageSection id="interviews" className="invert">
              <SectionWrapper>
                <MpkInverviewFlex container_gap="40px">
                  <iframe
                    className="mpk-video"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/ftAYBLSRLd0?si=RzlurKccaxYj3vdm;controls=0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
                  <ContentWrapper className="center">
                    <MPKSubHeader className="inverted">
                      {t('mpk-watch-online')}
                    </MPKSubHeader>
                    <Text style={{ textAlign: 'left' }}>
                      {t('mpk-jury-content')}
                    </Text>
                    <StyledButton
                      className="outlined"
                      to={
                        currentLang === 'pl'
                          ? '/news/interviews'
                          : '/en/news/interviews'
                      }>
                      {t('mpk-see-more-videos')}
                    </StyledButton>
                  </ContentWrapper>
                </MpkInverviewFlex>
              </SectionWrapper>
            </PageSection>
            <PageSection bg_img={Bubbles2} bg_img_pos="center right">
              <SectionWrapper>
                <SectionHeader>
                  <strong>{t('mpk-edition')}</strong>
                </SectionHeader>
                <Text className="center">{t('mpk-edition-content')}</Text>
                <GalleryComponent content={GalleryImageData} />
              </SectionWrapper>
            </PageSection>
            <PageSection>
              <SectionWrapper>
                <SectionHeader>
                  <strong>{t('mpk-partners-header')}</strong>
                </SectionHeader>
                <MPKSubHeader>{t('mpk-partners')}</MPKSubHeader>
                <ImageGrid>
                  <SponsorImage src={SGH} />
                  <SponsorImage src={AstraZeneca} />
                  <SponsorImage src={PARP} />
                  <SponsorImage src={GPW} />
                  <SponsorImage src={OFF} />
                  <SponsorImage src={Vestbee} />
                  <SponsorImage src={NCBR} />
                  <SponsorImage src={NTFY} />
                </ImageGrid>
                <MPKSubHeader>{t('mpk-investors')}</MPKSubHeader>
                <MPKFundingInfo>
                  <div>
                    <Trans t={t} i18nKey={'mpk-public-task'} />
                  </div>
                  <div>
                    <Trans t={t} i18nKey={'mpk-funding-amount'} />
                    <br />
                    <Trans t={t} i18nKey={'mpk-funding-value'} />
                  </div>
                  <div>
                    <Trans t={t} i18nKey={'mpk-funding-description'} />
                  </div>
                </MPKFundingInfo>
                <ImageGrid style={{ paddingTop: '20px' }}>
                  <SponsorImage className="" src={RP} />
                  <SponsorImage className="full" src={NIW} />
                </ImageGrid>
              </SectionWrapper>
            </PageSection>
          </PageWrapper>
        </PageBox>
      </Layout>
    </ThemeProvider>
  );
}
const NavigationHeight = '112px';
const PageBox = styled.div`
  font-family: 'Raleway';
  background-color: ${(props) => props.theme.background};
  margin-top: ${(props) => (!props.withHeader ? NavigationHeight : '0')};
`;

export default MpkPage;
